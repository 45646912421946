import jQuery from 'jquery';
const $ = jQuery;

window.jQuery = $;
window.$ = $;

import 'cssuseragent';
import 'jquery.easing';

import ImgLazyLoad from './module/img-lazy-load';

if (navigator.platform == "iPad" ||        // -iOS12
  (navigator.platform == "MacIntel" &&   // iPadOS
  navigator.userAgent.indexOf("Safari") != -1 &&
  navigator.userAgent.indexOf("Chrome") == -1 &&
  navigator.standalone !== undefined)) {
  document.querySelector("meta[name='viewport']").setAttribute("content", "width=959");
}

class MainApp {
  isTouchDevice() {
    var result = false;
    if (window.ontouchstart === null) {
      result = true;
    }
    return result;
  }
  constructor() {
    this.$window              = $(window);
    this.imgLazyLoad    = new ImgLazyLoad(this);

    if(Number($('.check-res').width()) > 900) {
      this.viewMode = 'pc';
    } else {
      this.viewMode = 'sp';
    }

    if(!$('html').hasClass('ua-sp') && Number($('.check-tablet').width()) <= 960) {
      this.isTablet = true;
      // $('.Header').addClass('Header--small');
      // this.header.compact();
    }

    $(document).on('touchstart', 'a, button, .FeatureImgBlockItem, .GalleryGridItem, .SlideGalleryItem', (e) => {
      $(e.currentTarget).attr('data-touch', 'true');
    });
    $(document).on('mouseleave', 'a, button, .FeatureImgBlockItem, .GalleryGridItem, .SlideGalleryItem', (e) => {
      $(e.currentTarget).removeAttr('data-touch');
    });

    if(this.isTouchDevice()) {
      $('a').attr('data-touch', 'true');
      $('button').attr('data-touch', 'true');
    }

    $('.RoundBtn').each((i, e) => {
      $(e).prepend('<span></span>');
    });

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();
      });
    this.onWindowResize();
  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();

    this.onWindowScroll();
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();
    if(this.scrollTop < 0) this.scrollTop = 0;
    this.imgLazyLoad.update(this.scrollTop, this.windowHeight);
  }

  onLoad() {

  }
}

let mainApp = undefined;

$('document').ready(() => {
  $.ajax({'type':'get','url': './assets/images/symbol-defs.svg'}).done((data) => {
    let svg = $(data).find('svg');
    $('body').append(svg);
  });

  mainApp = new MainApp();
  console.log('ready');
});

$(window).on('load', () => {


  mainApp.onLoad();

});
